<template>
  <cs-page class="views-home-about">
    <div class="about-bg"></div>
    <div class="content-wrap">
      <h1 style="font-size: 32px">我们是<b>北京微海管理咨询有限公司</b></h1>
      <p style="font-size: 16px">
        北京微海管理咨询有限公司，成立于2015年12月，前身为海底捞人事部、财务部，<span
          >是一家专注于连锁服务业的人力资源及财务管理综合服务平台。</span
        ><br v-if="getBrowser == 'pc'"/>公司主要针对包括餐饮、零售等连锁型企业提供<span
          >咨询培训、SaaS软件工具（人力SaaS和财务SaaS）、服务平台（招聘、外包、绩效考核等）。</span
        >
      </p>
      <p style="font-size: 16px">
        微海旨在<span>通过咨询+SaaS+服务平台等一系列方式，给连锁企业提供服务，以提升人力资源及财务管理的效率与专业度，</span>把连锁企业的决策层从琐碎的事务中解放出来，用更多的时间与精力投入到门店的运营、商业模式的迭代中去，进一步推动中国连锁服务业的发展与规范。
      </p>
      <div class="row justify-content-between" style="margin-top: 40px">
        <div class="col-3 text-center">
          <img class="img" src="../../../public/img/mission.png" v-if="getBrowser == 'pc'"/>
          <img class="img" src="../../assets/使命.png" v-if="getBrowser == 'phone'"/>
          <h3>使命</h3>
          <p>让服务更美好，让连锁更简单</p>
        </div>
        <div class="col-3 text-center">
          <img class="img" src="../../../public/img/vision.png"  v-if="getBrowser == 'pc'"/>
          <img class="img" src="../../assets/愿景.png" v-if="getBrowser == 'phone'"/>
          <h3>愿景</h3>
          <p>成为受尊重的第三方服务机构</p>
        </div>
        <div class="col-3 text-center">
          <img class="img" src="../../../public/img/worth.png"  v-if="getBrowser == 'pc'"/>
          <img class="img" src="../../assets/价值观.png" v-if="getBrowser == 'phone'"/>
          <h3>价值观</h3>
          <p>双手改变命运</p>
        </div>
      </div>
    </div>
    <div class="logo-wrap">
      <h2>他们的共同选择</h2>
      <div class="ul-wrap">
        <div class="prev"></div>
        <ul class="list d-flex justify-content-center">
          <li v-for="(img, index) in source.list" :key="index">
            <img class="img" :src="img" />
          </li>
          <!--第二轮-->
          <li v-for="(img, index) in source.list" :key="'sub_' + index">
            <img class="img" :src="img" />
          </li>
        </ul>
        <div class="next"></div>
      </div>
    </div>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {
      source: {
        list: [],
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const res =
        await this.api.service.fpc_oapi_customerPic_CommonSelectCustomerPic(
          {},
          {}
        );
      await this.api.toast(res, 0);
      this.source.list = res.data;
      const style = document.createElement("style");
      style.textContent = `
        @keyframes move {
          0% {
            left: 0;
          }
          100% {
            left: -${this.source.list.length * 204}px;
          }
        }
      `;
      document.querySelector(".logo-wrap").appendChild(style);
    },
  },
};
</script>
<style lang="less" scoped>
 @media screen and (min-width: 320px) and (max-width: 1023px) {
   .views-home-about{
     width:100%;
    .about-bg {
      width: 100%;
      height: 10rem;
      background: url(../../../public/img/about-bg.jpg) no-repeat center;
      background-size: cover;
    }
     .content-wrap {
        width: 90%;
        margin-left:5%;
        background: #ffffff;
        padding:32px  16px;
        box-shadow: 0px 20px 20px -10px rgba(0, 61, 56, 0.1);
        border-radius: 10px;
        position: absolute;
        top: 100px;
        h1 {
         color: #041334;
         text-align: justify;
         font-family: "PingFang SC";
         font-size: 18px !important;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
          margin-bottom: 20px;
          b {
            font-size: 18px !important;
            font-weight: 700;
          }
        }
        p {
          // span {
          //   font-weight: 400;
          //   color: #202121;
          // }
           color: #666c78;
            text-align: justify;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .col-3 {
          width:92%;
          margin-left:4%;
          height:5rem;
          margin-bottom:20px;
          background:#F7F8FA;;
          display: flex;
          position: relative;
          align-items: center;
          .img {
            width: 50px;
            height: 50px;
          }
          h3 {

            letter-spacing: 1px;
            position: absolute;
            left:26%;
            top:15%;
            color: #041334;
            text-align: justify;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          p {
            font-size: 16px;
            font-weight: 200;
            position: absolute;
            left:26%;
            top:55%;
            color: #666c78;
            text-align: justify;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .logo-wrap {
        margin-top:860px;
        h2 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 40px;
          font-weight: 500;
          text-align: center;
          color: #18282e;
          font-family: PingFangSC-Medium;
        }
      }
      .ul-wrap {
        width: 100%;
        height: 180px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        top:50%;
        left:0;
        .prev,
        .next {
          width: 90px;
          height: 100%;
          position: absolute;
          top: 0;
        }
        .prev {
          left: -180px;
          box-shadow: 0 0 50px 180px #f3f5f6;
          z-index: 1;
        }
        .next {
          right: -180px;
          box-shadow: 0 0 50px 180px #f3f5f6;
        }
        .list {
          padding: 0;
          position: absolute;
          left: 0;
          top: 0;
          animation: move 20s linear infinite;
        }
        li {
          list-style: none;
          width: 120px;
          height: 120px;
          display: block;
          margin: 0 12px;
          .img {
            width: 100%;
            height: 100%;
          }
        }
      }
   }
}
@media screen and (min-width: 1024px) {
 .views-home-about {
  .about-bg {
    width: 100%;
    height: 438px;
    background: url(../../../public/img/about-bg.jpg) no-repeat center;
    background-size: cover;
  }
  .content-wrap {
    width: 1200px;
    height: 551px;
    padding: 80px;
    background: #ffffff;
    box-shadow: 0px 20px 20px -10px rgba(0, 61, 56, 0.1);
    border-radius: 10px;
    position: absolute;
    top: 160px;
    left: 50%;
    margin-left: -600px;
    h1 {
      font-family: PingFangSC-Thin;
      color: #202121;
      font-weight: 200;
      line-height: 45px;
      margin-bottom: 40px;
      b {
        font-weight: 600;
        font-family: PingFangSC-Semibold;
      }
    }
    p {
      span {
        font-weight: 400;
        color: #202121;
      }
    }
    .col-3 {
      .img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
        letter-spacing: 1px;
        font-weight: 600;
        color: #202121;
      }
      p {
        font-size: 16px;
        font-weight: 200;
        color: #202121;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
  .logo-wrap {
    margin-top: 350px;
    margin-bottom: 80px;
    h2 {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 40px;
      font-weight: 500;
      text-align: center;
      color: #18282e;
      font-family: PingFangSC-Medium;
    }
  }
  .ul-wrap {
    width: 1280px;
    height: 180px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .prev,
    .next {
      width: 90px;
      height: 100%;
      position: absolute;
      top: 0;
    }
    .prev {
      left: -180px;
      box-shadow: 0 0 50px 180px #f3f5f6;
      z-index: 1;
    }
    .next {
      right: -180px;
      box-shadow: 0 0 50px 180px #f3f5f6;
    }
    .list {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      animation: move 20s linear infinite;
    }
    li {
      list-style: none;
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 12px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
}

</style>
